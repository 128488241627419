import instance from "../utils/request";
import {
    jyj_columnfirst_management,
    jyj_content_management,
    jyj_download_group,
    jyj_download_manage,
    SysTenant
} from "@/model/sysModel";

/**
 * 获取所有教育局
 */
export const queryAllTenantList = (): Res<SysTenant[]> => instance.get('/jeecg-system/sys/tenant/queryAllTenantList');

/**
 * 一级栏目管理-分页列表查询
 */
export const queryHomeList = (params: object): Res<jyj_columnsecond_management<jyj_columnfirst_management[]>> => instance.get('/jeecg-education/education/jyjColumnfirstManagement/queryHomeList', {params});

/**
 * 二级栏目管理-首页列表查询
 */
export const jyjColumnsecondManagementQueryHomeList = (params: object): Res<jyj_columnsecond_management<jyj_columnfirst_management[]>> => instance.get('/jeecg-education/education/jyjColumnsecondManagement/queryHomeList', {params});

/**
 * 二级栏目管理-内容列表查询
 */
export const jyjContentManagementQueryHomeList = (params: object): Res<[]> => instance.get('/jeecg-education/education/jyjContentManagement/queryHomeList', {params});

/**
 * 内容资讯管理-通过id查询
 */
export const queryById = (params: object): Res<jyj_content_management> => instance.get('/jeecg-education/education/jyjContentManagement/queryById', {params});

/**
 * 官网-查询下载分类
 */
export const queryOfficialWebsiteList = (params: object): Res<[]> => instance.get('/jeecg-education/education/jyjDownloadGroup/queryOfficialWebsiteList', {params});

/**
 * 官网-根据分类查询下载数据
 */
export const queryOfficialWebsiteDownloadList = (params: object): Res<[]> => instance.get('/jeecg-education/education/jyjDownloadManage/queryOfficialWebsiteDownloadList', {params});

/**
 * 教育部公开
 */
export const queryCommonContentList = (params: object): Res<[]> => instance.get('/education/jyjCommonContent/queryCommonContentList', {params});

/**
 * 内容资讯管理-首页展示查询
 */
export const queryShowList = (): Res<object> => instance.get('/jeecg-education/education/jyjContentManagement/queryShowList');

/**
 * 教育局学期管理-首页查询学期下拉数据
 */
export const queryHomeAllList = (): Res<[]> => instance.get('/jeecg-education/education/jyjSemesterManage/queryHomeAllList');

/**
 * 教育局学期管理-首页学期周查询
 */
export const queryHomeWeekList = (params: object): Res<[]> => instance.get('/jeecg-education/education/jyjSemesterManage/queryHomeWeekList', {params});

/**
 * 学校管理-首页下拉列表查询
 */
export const queryHomeSchoolNameList = (): Promise<[]> => instance.get('/jeecg-education/education/jyjSchoolManage/queryHomeSchoolNameList');

/**
 * 查询菜品公式
 */
export const queryHomeRecipes = (params: object): Res<[]> => instance.get('/jeecg-education/education/jyjSemesterManage/queryHomeRecipes', {params});
