import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
    },
    {
        path: '/list',
        name: 'list',
        component: () => import(/* webpackChunkName: "about" */ '../views/ListView.vue')
    },
    {
        path: '/ListDetails',
        name: 'ListDetails',
        component: () => import(/* webpackChunkName: "about" */ '../views/ListDetailsView.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        return {left: 0, top: 0}
    }

})

export default router
