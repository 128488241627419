import { defineStore } from "pinia";
import { FIRST_COLUMN_KEY, TENANT_ID } from "@/enums/cacheEnum";
import { jyj_columnfirst_management } from "@/model/sysModel";

export const useStore = defineStore("storeId", {
  state: () => {
    return {
      // 教育局ID
      tenantId: "",
      // 一级栏目
      // @ts-ignore
      firstColumn: {},
      // 导航栏透明
      bgColorGradient: true,
    };
  },
  getters: {
    getTenantId: (state) => {
      // @ts-ignore
      return state.tenantId || localStorage.getItem(TENANT_ID);
    },
    getFirstColumn: (state) => {
      // @ts-ignore
      return state.firstColumn || localStorage.getItem(FIRST_COLUMN_KEY);
    },
    getbgColorGradient(state) {
      return state.bgColorGradient;
    },
  },
  actions: {
    setTenantId(value: string) {
      this.tenantId = value;
      localStorage.setItem(TENANT_ID, value);
    },
    setFirstColumn(value: object) {
      this.firstColumn = value;
      localStorage.setItem(FIRST_COLUMN_KEY, JSON.stringify(value));
    },
    setbgColorGradient(value: boolean) {
      this.bgColorGradient = value;
    },
  },
});
